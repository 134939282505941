/* @flow */

import { store } from '@enovar/bsboard-core';

import configurations from '../config';

import epics from './epics';
import reducers from './reducers';

store.injectAsyncEpics(epics);
store.injectAsyncReducer(store, reducers);

export const { endpoint } = configurations;

store.ajax.add('bsboard', {
  uri: endpoint
});

store.ajax.add('authentication', {
  uri: 'https://eauthtest.enovar.app/eauthtest/login'
});

export default store;
