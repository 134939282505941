import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Main from './Main';

type Props = {};

class MainContainer extends Component<Props> {
  static registerComponent() {
    return {
      name: 'Main',
    };
  }

  render() {
    return <Main />;
  }
}

MainContainer.defaultProps = {};

function mapStateToProps(state = Immutable.Map({})) {
  return state.toJS();
}

export default connect(mapStateToProps)(MainContainer);
