export default (() => {
  const configurations = {};

  switch (process.env.REACT_APP_STAGE) {
    case 'production':
      return Object.assign(configurations, {
        endpoint: '/v3/',
      });
    case 'developement':
    default:
      return Object.assign(configurations, {
        // endpoint: 'http://localhost:8081/bsboard3/v3/'
        // endpoint: 'https://dev.liberto.enovar.app/v3/',
        endpoint: 'https://demo.izicash.app/v3/',
      });
  }
})();