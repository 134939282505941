import React, { Component } from 'react';

type Props = {};

class Main extends Component<Props> {
  state = {};

  render() {
    return (
      <div>
        <span>Main Credito Screen</span>
      </div>
    );
  }
}

Main.defaultProps = {};

export default Main;
