/* @flow */

/**
 * Importe os reducers de funções puras aqui.
 *
 * -> import ui from './ui/reducer';
 *
 * -> reducer.js
 *
 *  function reducer(state = initialState, action) {
 *    const { type } = action;
 *    (...)
 *    return state;
 *  }
 *
 *  export default reducer;
 */

export default {
  /** Adicione aqui o reducer assim como deve ser seu nome no state. */
};
