/* @flow */

import { combineEpics } from 'redux-observable';

/**
 * Importe epics criados aqui.
 *
 * -> import ui from './ui/epic';
 *
 * -> epic.js
 *
 *  const ui = action$ =>
 *    action$
 *      .ofType(...)
 *      (...)
 *
 *  export default combineEpics(ui);
 */

/** Adicione aqui os epics por parametros. */
export default combineEpics();
