import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '@enovar/bsboard-core';

//  import store from "../../store";

import Credit from './Main';

// const registerApplications = store.actions.applications;

export const subjects = [
  {
    name: 'Componentes',
    items: [
      {
        name: 'Todos os Componentes',
        to: '/overview/all',
      },
      {
        name: 'Buttons',
        to: '/overview/buttons',
      },
      {
        name: 'Forms',
        to: '/overview/forms',
      },
      {
        name: 'Tables',
        to: '/overview/tables',
      },
      {
        name: 'Typography',
        to: '/overview/typography',
      },
    ],
  },
];

//  store.dispatch(
//   registerApplications({
//     overview: {
//       icon: "support",
//       name: "Overview",
//       path: "/overview"
//     }
//   })
// );

export default ({ childProps }) => {
  const path = '/credit';
  const routes = () => (
    <Switch>
      <PrivateRoute path={`${path}`} exact key="credit" component={Credit} props={childProps} />
    </Switch>
  );

  return (
    <Route path={path} props={childProps}>
      <div className="AppSegments">
        {/* <Aside subjects={subjects} />	 */}
        {routes()}
      </div>
    </Route>
  );
};
