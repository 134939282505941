/* @flow */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */

import React, { Component } from 'react';
import { Navigator, theme, blendColors } from '@enovar/bsboard-core';

import 'antd/dist/antd.css';

import './App.css';
import './store';
import screens from './navigation';

const { StyleSheet } = theme;

const colors = {
  primary: '#88C7EE',
  complementary: '#004272',
  acent: '#14A4F2',
  grayExtraLight: '#ECECEC',
  grayLight: '#c6c6c6',
};

const chartColors = [
  blendColors(colors.primary, colors.complementary, 0.1),
  blendColors(colors.primary, colors.complementary, 1),
  blendColors(colors.grayLight, colors.grayExtraLight, 0.3),
  blendColors(colors.primary, colors.complementary, 0.8),
  blendColors(colors.primary, colors.complementary, 0.5),
  blendColors(colors.grayLight, colors.grayExtraLight, 0.2),
  blendColors(colors.primary, colors.complementary, 0.9),
  blendColors(colors.primary, colors.complementary, 0.4),
  blendColors(colors.grayLight, colors.grayExtraLight, 0.7),
  blendColors(colors.primary, colors.complementary, 0.6),
];

const fontFamily = 'Futura, sans-serif';
const breakpoints = {
  small: '@media (max-width: 639px)',
  medium: '@media (max-width: 1047px)',
  large: '@media (min-width: 1048px)',
};

StyleSheet.registerTheme({
  breakpoints,
  colors,
  chartColors,
  components: {
    Aside: {
      background: colors.grayExtraLight,
      // background: `${colors.grayExtraLight} url("/assets/header-background.png") no-repeat`,
      width: 216,
      screen: {
        color: '#383838',
      },
      subject: {
        color: '#474545',
      },
    },
    Container: {
      background: '#F8F8F8',
      minHeight: 'calc(100vh - 64px)',
    },
    Header: {
      background: '#F8F8F8',
      height: 64,
    },
    Sidebar: {
      background: '#004272',
      width: 64,
    },
    input: {
      borderColor: colors.grayLight,
      borderRadius: 4,
      borderSize: 1,
      fontFamily,
      fontSize: 12,
      boxShadow: {
        color: colors.grayExtraLight,
        offset: 'inset 0 1px 3px',
      },
    },
    label: {
      color: '#949494',
      display: 'block',
      fontFamily,
      fontSize: 9,
      letterSpacing: '0',
      textTransform: 'uppercase',
    },
  },
  text: {
    color: '#2D3436',
    size: 12,
  },
  unit: 10,
  fontFamily,
});

class App extends Component {
  state = {};

  render() {
    return (
      <div className="App">
        <Navigator screens={screens} />
      </div>
    );
  }
}

export default App;
